import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'

export default class StatusService {
  $axios: NuxtAxiosInstance
  s3BucketStatusEndpoint?: string
  isTheFirstTime: boolean

  constructor (context: Context) {
    this.$axios = context.$axios
    this.s3BucketStatusEndpoint = (<string>import.meta.env.VITE_S3_BUCKET_STATUS_ENDPOINT)
    this.isTheFirstTime = true
  }

  public getEndpointStatus () {
    if (!this.s3BucketStatusEndpoint && this.isTheFirstTime) {
      this.isTheFirstTime = false
      throw new Error('The variable VITE_S3_BUCKET_STATUS_ENDPOINT is not defined in the .env')
    }
    return this.s3BucketStatusEndpoint && this.$axios.$get(this.s3BucketStatusEndpoint)
  }
}
